import { ImageOptions } from "./types";

export const convertToBase64 = (source: string) =>
  "undefined" !== typeof Buffer ? Buffer.from(source).toString("base64") : btoa(source);

export const hexToRgbA = (hex: string, _alpha?: number) => {
  var c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return {
      r: (c >> 16) & 255,
      g: (c >> 8) & 255,
      b: c & 255,
      alpha: Number(_alpha),
    };
  }
  throw new Error("Bad Hex");
};

export const parseEdits = (options: ImageOptions = {}) => {
  const edits: any = {
    resize: {},
    jpeg: {
      quality: 70,
    },
    webp: {
      quality: 70,
    },
  };

  if (options.resize) {
    if (options.width) {
      edits.resize.width = options.width;
    }
    if (options.height) {
      edits.resize.height = options.height;
    }

    edits.resize.fit = options.resize;
  }

  if (options.fillColor) {
    edits.resize.background = hexToRgbA(options.fillColor, 1);
  }

  if (options.backgroundColor) {
    edits.flatten = {
      background: hexToRgbA(options.backgroundColor, undefined),
    };
  }

  if (options.grayscale) {
    edits.grayscale = options.grayscale;
  }

  if (options.flip) {
    edits.flip = options.flip;
  }

  if (options.flop) {
    edits.flop = options.flop;
  }
  if (options.negative) {
    edits.negative = options.negative;
  }
  if (options.flatten) {
    edits.flatten = options.flatten;
  }
  if (options.normalize) {
    edits.normalize = options.normalize;
  }

  if (options.tint) {
    edits.tint = options.tint;
  }

  if (options.smartCrop) {
    edits.smartCrop = {};
    if (options.smartCrop.faceIndex) {
      edits.smartCrop.faceIndex = options.smartCrop.faceIndex;
    }
    if (options.smartCrop.padding) {
      edits.smartCrop.padding = options.smartCrop.padding;
    }
  }

  if (Object.keys(edits.resize).length === 0) {
    delete edits.resize;
  }

  if (options.quality) {
    edits.jpeg = {
      quality: options.quality,
    };
    edits.webp = {
      quality: options.quality,
    };
  }

  if (options.blur) {
    if (options.blur > 0.3 && options.blur <= 1000) {
      edits.blur = options.blur;
    }
  }
  return edits;
};
