import React from "react";
import {
  GatsbyImage,
  getImageData,
  IUrlBuilderArgs,
} from "gatsby-plugin-image";

import { ImageOptions, KeystoneImageProps } from "./types";
import { parseEdits, convertToBase64 } from "./helper";

const BASE_URL = "https://static.steppelink.mn";
const BUCKET_NAME = "cdn.sites.steppelink.mn";

const defaultUrlBuilder = (
  key: string,
  { options }: IUrlBuilderArgs<ImageOptions>
) => {
  const edits = parseEdits(options);

  const request = {
    bucket: BUCKET_NAME,
    edits,
    key,
  };

  return `${BASE_URL}/${convertToBase64(JSON.stringify(request))}`;
};

const defaultPlaceholder = (key: string) =>
  defaultUrlBuilder(key, {
    baseUrl: BASE_URL,
    width: 4,
    height: 4,
    format: "auto",
    options: {
      resize: "cover",
      width: 4,
      height: 4,
      blur: 10,
    },
  });

const getKeystoneImageData = ({
  image,
  width,
  height,
  layout,
  backgroundColor,
  breakpoints,
  formats,
  aspectRatio,
  options,
  urlBuilder,
  baseUrl,
  placeholderURL,
}) => {
  return getImageData({
    baseUrl,

    sourceWidth: image.width,
    sourceHeight: image.height,
    width,
    height,
    layout,
    backgroundColor,
    breakpoints,
    formats,
    aspectRatio,
    options,
    urlBuilder,
    pluginName: "../gatsby-image-keystone",
    placeholderURL,
  });
};

export const KeystoneImage: React.FunctionComponent<KeystoneImageProps> = ({
  image,
  width,
  height,
  layout,
  backgroundColor,
  aspectRatio,
  options,
  formats,
  breakpoints,
  urlBuilder,
  baseUrl,
  placeholderURL,
  ...props
}) => {
  const fileKey = `${baseUrl || "unknown-site"}/${image.key}`;

  const imageData = getKeystoneImageData({
    image,
    width,
    height,
    layout,
    backgroundColor,
    formats,
    breakpoints,
    aspectRatio,
    options,
    urlBuilder: urlBuilder
      ? urlBuilder
      : (params) => defaultUrlBuilder(fileKey, params),
    baseUrl,
    placeholderURL: placeholderURL
      ? placeholderURL
      : defaultPlaceholder(fileKey),
  });

  const alt = props.alt || "";

  return <GatsbyImage image={imageData} alt={alt} {...props} />;
};

// module.exports = {
//   KeystoneImage,
// };
