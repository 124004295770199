import * as React from "react";
import { KeystoneImage } from "@/components/gatsby-image-keystone";
type ImageProps = {
  appearance: string;
  featureImage: any;
};

export const Image = ({ appearance, featureImage }: ImageProps) => {
  const image = featureImage.data === undefined ? "" : featureImage.data.image;

  return (
    <div>
      {featureImage.data !== "undefined" ? (
        <KeystoneImage
          className={
            appearance === "background"
              ? "xl:min-w-max xl:max-h-max md:drop-shadow-[-35px_35px_0px_#CE6F52] drop-shadow-[-15px_15px_0px_#CE6F52]"
              : ""
          }
          baseUrl="steppelink"
          image={{
            alt: image?.id,
            width: 400,
            // height: 400,
            height: image?.height * (400 / image?.width),
            key: `${image?.id}.${image?.extension}`,
          }}
          alt={image?.id}
          layout="fullWidth"
        />
      ) : (
        ""
      )}
    </div>
  );
};
