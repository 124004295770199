import React, { ComponentProps } from "react";
import { DocumentRenderer } from "@keystone-6/document-renderer";
import { Image } from "../components/Image";
type CustomRendererProps = ComponentProps<typeof DocumentRenderer>;

const defaultElementRenderers: CustomRendererProps["renderers"] = {
  block: {
    block: React.Fragment,
    divider: () => {
      return (
        <div className="my-5 max-w-[550px] mx-auto">
          {" "}
          <hr />
        </div>
      );
    },
    paragraph: ({ children, textAlign }) => {
      let textStyle = "w-full text-left mb-4";
      if (textAlign == "end") {
        textStyle = "text-right w-full mb-4";
      } else if (textAlign == "center") {
        textStyle = "text-center w-full mb-4";
      }

      if (children[0].props.node.text == "") {
        return <br />;
      } else {
        return <p className={textStyle}>{children}</p>;
      }
    },
    blockquote: ({ children }) => {
      return (
        <blockquote className="border-l-4 border-theme text-black">
          <div className="text-[28px] leading-[42px]  pl-4">{children}</div>
        </blockquote>
      );
    },

    heading: ({ children, textAlign, level }) => {
      switch (level) {
        case 1:
          if (textAlign == "end") {
            return (
              <div className="w-full text-right">
                <h1>{children}</h1>
              </div>
            );
          } else if (textAlign == "center") {
            return (
              <div className="w-full text-center">
                <h1>{children}</h1>
              </div>
            );
          } else {
            return <h1 className="">{children}</h1>;
          }
        case 2:
          if (textAlign == "end") {
            return (
              <div className="w-full text-right">
                <h2>{children}</h2>
              </div>
            );
          } else if (textAlign == "center") {
            return (
              <div className="w-full text-center">
                <h2>{children}</h2>
              </div>
            );
          } else {
            return <h2 className="">{children}</h2>;
          }
        case 3:
          if (textAlign == "end") {
            return (
              <div className="w-full text-right">
                <h3>{children}</h3>
              </div>
            );
          } else if (textAlign == "center") {
            return (
              <div className="w-full text-center">
                <h3>{children}</h3>
              </div>
            );
          } else {
            return <h3 className="">{children}</h3>;
          }
        case 4:
          if (textAlign == "end") {
            return (
              <div className="w-full text-right">
                <h3>{children}</h3>
              </div>
            );
          } else if (textAlign == "center") {
            return (
              <div className="w-full text-center">
                <h3>{children}</h3>
              </div>
            );
          } else {
            return <h3 className="">{children}</h3>;
          }
        case 5:
          if (textAlign == "end") {
            return (
              <div className="w-full text-right lg:text-[18px]">
                <h5>{children}</h5>
              </div>
            );
          } else if (textAlign == "center") {
            return (
              <div className="w-full text-center lg:text-[18px]">
                <h5>{children}</h5>
              </div>
            );
          } else {
            return (
              <h5 className="font-[500] lg:text-[18px] lg:text-justify">
                {children}
              </h5>
            );
          }
        case 6:
          if (textAlign == "end") {
            return (
              <div className="w-full text-right lg:text-[16px]">
                <h6>{children}</h6>
              </div>
            );
          } else if (textAlign == "center") {
            return (
              <div className="w-full text-justify lg:text-[16px]">
                <h6>{children}</h6>
              </div>
            );
          } else {
            return <h6 className="font-[300] lg:text-[16px] ">{children}</h6>;
          }
        default:
          return <h6 className="">{children}</h6>;
      }
    },
    layout: ({ children, layout }) => {
      const layoutCount = () => {
        let sum = 0;
        layout.map((x) => (sum += x));
        return sum;
      };

      return (
        <div
          className={`xl:container xl:px-0 px-5 
            grid  gap-14 grid-cols-1 md:grid-cols-${layoutCount()}`}
          // className={`xl:container xl:px-0 px-5 py-5
          //   grid gap-10  grid-cols-1 md:grid-cols-[${layout
          //     .map((x) => `_${x}fr`)
          //     .join("")}]`}

          // className="xl:container py-[1rem] grid gap-10 grid-cols-[_1fr_1fr]"
        >
          {children.map((element, i) => (
            <div className="" key={i}>
              {element}
            </div>
          ))}
        </div>
      );
    },
    list: ({ children, type }) => {
      if (type === "ordered") {
        return (
          <ol>
            {" "}
            {children.map((child) => (
              <li className="mb-4" key={children.indexOf(child)}>
                {child}
              </li>
            ))}
          </ol>
        );
      } else {
        return (
          <ul className="list-inside list-disc ">
            {children.map((child) => (
              <li className="" key={children.indexOf(child)}>
                {child}
              </li>
            ))}
          </ul>
        );
      }
    },
  },
  inline: {
    bold: ({ children }) => {
      return <strong>{children}</strong>;
    },
    italic: ({ children }) => {
      return <em>{children}</em>;
    },
    link: ({ children, href }) => {
      return (
        <a
          className="text-theme hover:text-black"
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    },
  },
};

const customComponentRenderers: CustomRendererProps["componentBlocks"] = {
  image: (props) => {
    return <Image {...props} alt="" />;
  },
};

export function CustomRenderer({ document }: CustomRendererProps) {
  return (
    <DocumentRenderer
      renderers={defaultElementRenderers}
      componentBlocks={customComponentRenderers}
      document={document}
    />
  );
}
